export const featuredPortfolio = [
    {
      id: 1,
      title: "Blogging Website",
      img: "assets/rotary.jpeg",
    },
    {
      id: 2,
      title: "Dawnbreakers Website",
      img:"assets/rotary.jpeg",
        
    },


  ];
  
  export const webPortfolio = [
    {
      id: 1,
      title: "Coming Soon",
      img: "assets/construct.jpeg",
    },
    {
      id: 2,
      title: "Coming Soon",
      img: "assets/construct.jpeg",
    },
    {
      id: 3,
      title: "Coming Soon",
      img: "assets/construct.jpeg",
    },

  ];
  
  export const mobilePortfolio = [
    {
      id: 1,
      title: "Coming Soon",
      img:"assets/phone.jpeg",
      
    },
    {
      id: 2,
      title: "Coming Soon",
      img:"assets/phone.jpeg",
        
    },
    {
      id: 3,
      title: "Coming Soon",
      img:"assets/phone.jpeg",
        
    },


  ];
  
  export const gamePortfolio = [
    {
      id: 1,
      title: "Coming Soon",
      img:"assets/game.jpeg",
        
    },
    {
      id: 2,
      title: "Coming Soon",
      img:"assets/game.jpeg",
      
    },
    {
      id: 3,
      title: "Coming Soon",
      img:"assets/game.jpeg",
        
    },



  ];
  
 
